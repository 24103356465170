import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { IconComponent } from '@1stdigital/ng-sdk/icon';

import { InboxMessageModalComponent } from '../inbox-message-list/inbox-message-list.component';

@Component({
  selector: 'app-inbox-widget',
  standalone: true,
  imports: [ButtonComponent, IconComponent, MatMenuModule, InboxMessageModalComponent],
  templateUrl: './inbox-widget.component.html',
  styleUrl: './inbox-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxWidgetComponent {
  @Input() hasNewMessage = false;

  closeWidget(trigger: MatMenuTrigger): void {
    trigger.closeMenu();
  }
}
