import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { StorageKeys } from '@app/core/models';
import { ClientAccessorDto, ClientSelection, ServiceEntityDto } from '@app/core/models/interfaces/client.interface';
import { StorageService } from '@app/core/services/storage.service';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  serviceEntityFallback?: ServiceEntityDto;

  activeClient$ = new ReplaySubject<ClientAccessorDto>(1);
  // Need to revisit this place to check the flow and when entity may be undefined and later make updates based on the actual flow.
  activeServiceEntity$ = new BehaviorSubject<ServiceEntityDto | undefined>(this.serviceEntityFallback);

  constructor(
    private storageService: StorageService,
    private router: Router
  ) {}

  getClientDefaultSelection(): ClientSelection | null {
    return this.storageService.getItem<ClientSelection>(StorageKeys.APP_CLIENT_SELECTION);
  }

  // NOTE: that client selection fetching process may become asynchronous operation in future
  // NOTE: Probably, revealing this method for public usage was a bad idea
  getClientSelection(): ClientSelection | null {
    const selection: ClientSelection | null = this.storageService.getSessionItem(StorageKeys.APP_CLIENT_SELECTION);

    return selection ?? this.storageService.getItem(StorageKeys.APP_CLIENT_SELECTION);
  }

  saveClientSelectionTemporary(selection: ClientSelection): void {
    this.storageService.setSessionItem(StorageKeys.APP_CLIENT_SELECTION, selection);
  }

  saveClientSelection(selection: ClientSelection): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.setItem(StorageKeys.APP_CLIENT_SELECTION, selection);
  }

  forgetClientSelection(): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
    this.storageService.removeItem(StorageKeys.APP_CLIENT_SELECTION);
  }

  forgetTemporarySelection(): void {
    this.storageService.removeSessionItem(StorageKeys.APP_CLIENT_SELECTION);
  }

  emitValues(client: ClientAccessorDto, entity: ServiceEntityDto): void {
    this.activeClient$.next(client);
    this.activeServiceEntity$.next(entity);
  }

  start(clients: ClientAccessorDto[]): void {
    const selection = this.getClientSelection();
    // client with active status is selected as priority
    const newlySelectedClient: ClientAccessorDto = clients.find((c) => c.status === 'active') || clients[0];

    if (!selection) {
      this.saveClientSelection({
        clientId: newlySelectedClient.id,
        clientName: newlySelectedClient.name,
        clientType: newlySelectedClient.type,
        serviceEntityId: newlySelectedClient.serviceEntities[0].id,
        clientStatus: newlySelectedClient.status,
      });
      this.emitValues(newlySelectedClient, newlySelectedClient.serviceEntities[0]);
      this.onHasOneInactiveClient(clients);
      return;
    }

    const foundClient = clients.find((c) => c.id === selection.clientId);
    const foundEntity = foundClient?.serviceEntities.find((e) => e.id === selection.serviceEntityId);

    if (!foundClient || !foundEntity) {
      // Optionally add something to notify user about error
      this.emitValues(newlySelectedClient, newlySelectedClient.serviceEntities[0]);
      this.forgetClientSelection();
      return;
    }

    this.emitValues(foundClient, foundEntity);
    this.onHasOneInactiveClient(clients);
  }

  onHasOneInactiveClient(clients: ClientAccessorDto[]): void {
    const hasOnlyOneInactiveClient: boolean = clients.length === 1 && clients[0].status !== 'active';

    if (hasOnlyOneInactiveClient) {
      this.router.navigate(['inactive-client']);
    }
  }
}
