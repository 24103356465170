import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';

import { MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtCheckboxComponent, FdtDropdownModule } from '@1stdigital/ng-sdk/controls';
import { EntityDropdownItem } from '@1stdigital/ng-sdk/core';
import { FdtFormFieldModule } from '@1stdigital/ng-sdk/form-field';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtSkeletonDirective, StatusLabelComponent } from '@1stdigital/ng-sdk/shared';
import {
  accountStatusConfig,
  ClientAccessorDto,
  ClientSelection,
  ServiceEntityDto,
} from '@app/core/models/interfaces/client.interface';
import { AnalyticsService } from '@app/core/services';
import { ClientService } from '@app/core/services/client.service';
import { LayoutApiService } from '@app/layout/layout-api.service';
import { DisplayAmountComponent } from '@app/shared/display-property/display-amount/display-amount.component';
import { FullSizeModalWrapperComponent } from '@app/shared/full-size-modal-wrapper/full-size-modal-wrapper.component';
import { WidgetStatus } from '@app/shared/models/widget-status.interface';
import {
  SimpleToggleGroupItemComponent,
  ToggleGroupComponent,
  ToggleItemContainerComponent,
} from '@app/shared/toggle-group';
import { combineLatest, distinctUntilChanged, filter, map, Subscription } from 'rxjs';

import { EntitySwitcherOptionComponent } from '../entity-switcher-option/entity-switcher-option.component';

export interface EntitySwitchSubmitResult {
  clientOrEntityChanged: boolean;
}

interface PrepareSwitchOptions {
  clients: ClientAccessorDto[];
  activeClient: ClientAccessorDto;
  activeEntity?: ServiceEntityDto;
}

export interface ActiveEntityPair {
  clientId: number;
  serviceEntityId?: number;
}

@Component({
  selector: 'app-entity-switcher',
  standalone: true,
  imports: [
    ToggleGroupComponent,
    SimpleToggleGroupItemComponent,
    ReactiveFormsModule,
    FdtButtonModule,
    AsyncPipe,
    ToggleItemContainerComponent,
    FdtDropdownModule,
    FdtFormFieldModule,
    FdtIconModule,
    NgTemplateOutlet,
    FdtSkeletonDirective,
    DisplayAmountComponent,
    NgClass,
    MatTooltip,
    EntitySwitcherOptionComponent,
    StatusLabelComponent,
    FdtCheckboxComponent,
  ],
  templateUrl: './entity-switcher.component.html',
  styleUrl: './entity-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySwitcherComponent implements OnInit {
  readonly WidgetStatuses = WidgetStatus;
  readonly maxVisibleAmount: number = 3; // to control amount of max visible toggle items on the page;
  currentWidgetStatus = WidgetStatus.Loading;
  clients: EntityDropdownItem<ClientAccessorDto, number>[] = [];
  serviceEntities: EntityDropdownItem<ServiceEntityDto, number>[] = [];

  activeEntityPair!: ActiveEntityPair;
  previousSavedSelection: ClientSelection | null = null;
  selectionForm!: FormGroup;

  private clientIdChangeSubs?: Subscription; // is used to avoid memory leak in combineLatest;
  private entityIdChangeSubs?: Subscription;

  protected readonly accountStatusConfig = accountStatusConfig;

  constructor(
    private fb: FormBuilder,
    private layoutApiService: LayoutApiService,
    private clientService: ClientService,
    private dialogRef: MatDialogRef<FullSizeModalWrapperComponent>,
    private destroyRef: DestroyRef,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.selectionForm = this.initSelectionFormGroup();
    combineLatest([
      this.layoutApiService.getClientAccessorList(),
      this.clientService.activeClient$,
      this.clientService.activeServiceEntity$,
    ])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([clients, activeClient, activeEntity]) => {
        this.clientIdChangeSubs?.unsubscribe();
        this.entityIdChangeSubs?.unsubscribe();
        this.previousSavedSelection = this.clientService.getClientDefaultSelection();
        this.activeEntityPair = {
          clientId: activeClient.id,
          serviceEntityId: activeEntity?.id,
        };
        // NOTE: I added small comment about undefined activeServiceEntity$
        this.prepareSelectionFormForUse({ clients, activeClient, activeEntity });
        this.updateEntitySelectorOnClientChange();
        this.updateCheckboxOnEntityChange();
        this.currentWidgetStatus = WidgetStatus.Ready;
        this.cdr.markForCheck();
      });
  }

  onSelectionSubmit(): void {
    const newSelection = this.selectionForm.value;
    const result: EntitySwitchSubmitResult = {
      clientOrEntityChanged: true,
    };

    if (
      this.activeEntityPair.clientId === newSelection.clientId &&
      this.activeEntityPair?.serviceEntityId === newSelection.serviceEntityId
    ) {
      result.clientOrEntityChanged = false;
    }

    const entity = this.serviceEntities.find((e) => e.value === newSelection.serviceEntityId)!;
    const entityProps = {
      serviceEntityId: entity.entity.id,
      serviceEntityName: entity.entity.name,
    };

    if (newSelection.saveSelection) {
      this.clientService.saveClientSelection({
        ...newSelection,
      });
      this.analyticsService.track('Client service confirmed - set default', entityProps);
    } else {
      this.clientService.saveClientSelectionTemporary({
        ...newSelection,
      });
      this.analyticsService.track('Client service confirmed', entityProps);
    }

    if (result.clientOrEntityChanged) {
      const client = this.clients.find((c) => c.value === newSelection.clientId)!;
      this.clientService.emitValues(client.entity, entity.entity);
    }

    this.dialogRef.close(result);
  }

  private initSelectionFormGroup(): FormGroup {
    return this.fb.group({
      clientId: [undefined, [Validators.required]],
      serviceEntityId: [undefined, [Validators.required]],
      saveSelection: [false],
      // below data needed in other components
      clientName: [undefined],
      clientStatus: [undefined],
      clientType: [undefined],
    });
  }

  private isActiveEntityPairInSavedSelection(activeClientId: number, activeEntityId: number): boolean {
    if (!this.previousSavedSelection) {
      return false;
    }

    const isSameClient = this.previousSavedSelection.clientId === activeClientId;
    const isSameEntity = this.previousSavedSelection.serviceEntityId === activeEntityId;

    return isSameClient && isSameEntity;
  }

  private prepareSelectionFormForUse(options: PrepareSwitchOptions): void {
    const { clients, activeClient, activeEntity } = options;
    let client = clients.find((c) => c.id === activeClient.id);
    // If possible take the latest version of client object, but if not, use active client entity as a fallback
    client = client ?? activeClient;

    this.clients = clients.map((c) => ({
      value: c.id,
      label: c.name,
      entity: c,
      disabled: c.status !== 'active',
    }));
    this.serviceEntities = this.mapServiceEntitiesToDropdownItems(client.serviceEntities);

    this.selectionForm.patchValue({
      // If undefined, just keep input unselected
      serviceEntityId: activeEntity?.id,
      clientId: client.id,
      clientName: client.name,
      clientStatus: client.status,
      saveSelection: activeEntity && this.isActiveEntityPairInSavedSelection(activeClient.id, activeEntity.id),
    });
  }

  private updateEntitySelectorOnClientChange(): void {
    this.clientIdChangeSubs = this.selectionForm
      .get('clientId')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        map((clientId) => this.clients.find((c) => c.value === clientId)),
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((client) => {
        this.serviceEntities = this.mapServiceEntitiesToDropdownItems(client.entity.serviceEntities);
        // distinctUntilChanged because we reset value here and set client again
        // or else this will cause infinite loop
        this.selectionForm.reset({
          saveSelection: false,
          clientId: client.value,
          clientName: client.entity.name,
          clientType: client.entity.type,
          clientStatus: client.entity.status,
        });
      });
  }

  /*
    TODO: We considered transition to outputs for similar cases. To support it we also need to update toggle-group first.
    Added this comment, as reminder to consider when we make refactoring or have more time at least.
   */
  private updateCheckboxOnEntityChange(): void {
    this.entityIdChangeSubs = this.selectionForm
      .get('serviceEntityId')
      ?.valueChanges.pipe(
        distinctUntilChanged(),
        filter((entityId) => !!entityId && !!this.previousSavedSelection?.serviceEntityId),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((entityId) => {
        const sameIds = entityId === this.previousSavedSelection?.serviceEntityId;
        this.selectionForm.patchValue({ saveSelection: sameIds });
      });
  }

  private mapServiceEntitiesToDropdownItems(
    entities: ServiceEntityDto[]
  ): EntityDropdownItem<ServiceEntityDto, number>[] {
    return entities.map((entity: ServiceEntityDto) => ({
      value: entity.id,
      label: entity.name,
      entity: entity,
      disabled: !entity.isActive,
    }));
  }
}
