// courier.service.ts
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { delayedRetry } from '@app/shared/operators';
import { Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import { InboxMarkAllReadResponse, InboxMessagesResponse } from '../models/interfaces/inbox.interface';
import { ApplicationSettingsService } from './application-settings.service';
import { EnvironmentLoaderService } from './environment-loader.service';

@Injectable({
  providedIn: 'root',
})
export class CourierService {
  private wsUrl = `${this.envService.environment['courierWssUrl']}?clientKey=${this.envService.environment['courierClientKey']}`;
  private _ws$?: WebSocketSubject<unknown>;
  private messagesProps = `
    totalCount
    pageInfo {
      hasNextPage
    }
    data: nodes {
      actions(version: 2) {
        data
        content
        href
      }
      archived
      created
      data
      icon
      messageId
      preview
      read
      icon
      tags
      title
    }
`;
  public ws$ = new Subject();

  constructor(
    private http: HttpClient,
    private envService: EnvironmentLoaderService,
    private appSettingsService: ApplicationSettingsService
  ) {}

  private createGetMessagesQuery(): string {
    return `
      query GetMessageLists(
        $unreadParams: FilterParamsInput,
        $readParams: FilterParamsInput,
        $limit: Int = 10
      ) {
        unread: messages(params: $unreadParams, limit: $limit) {
          ${this.messagesProps}
        }
        read: messages(params: $readParams, limit: $limit) {
          ${this.messagesProps}
        }
      }
    `;
  }

  initWebSocket(clientId: string): void {
    this._ws$ = webSocket({
      url: this.wsUrl,
      openObserver: {
        next: () => {
          const subscriptionMessage = {
            action: 'subscribe',
            data: {
              channel: `${this.appSettingsService.userId}_${clientId}`,
              clientKey: this.envService.environment['courierClientKey'],
            },
          };

          this._ws$?.next(subscriptionMessage);
        },
      },
      closeObserver: {
        next: () => {
          console.log('WebSocket connection closed');
        },
      },
    });

    this._ws$.pipe(delayedRetry()).subscribe((data) => {
      this.ws$.next(data);
    });
  }

  getMessages(limit: number): Observable<InboxMessagesResponse> {
    const payload = {
      query: this.createGetMessagesQuery(),
      operationName: 'GetMessageLists',
      variables: {
        unreadParams: {
          status: 'unread',
        },
        readParams: {
          status: 'read',
        },
        limit,
      },
    };

    return this.http.post<InboxMessagesResponse>('/q', payload);
  }

  markAllAsRead(): Observable<InboxMarkAllReadResponse> {
    const payload = {
      operationName: 'TrackEvent',
      query: `
        mutation TrackEvent {
          markAllRead
        }
      `,
    };

    return this.http.post<InboxMarkAllReadResponse>('/q', payload);
  }

  disconnectWs(): void {
    this._ws$?.complete();
  }
}
