<div class="inbox-list">
  <div class="inbox-list__title">
    <span>
      Notifications
      @if (unreadMessagesVMSignal().totalCount) {
        <span class="inbox-list__message-count">
          {{ unreadMessagesVMSignal().totalCount }}
        </span>
      }
    </span>
    <button class="inbox-list__close-btn" fdt-fab-button color="tertiary" (click)="onModalClose()">
      <fdt-icon name="closeLine" size="24"></fdt-icon>
    </button>
  </div>
  <div class="inbox-list__content">
    @if (tabSelectedIndex === 0 && unreadMessagesVMSignal().totalCount) {
      <button
        class="inbox-list__mark-all-read button-1"
        fdt-ghost-button
        ghostType="with-icon"
        (click)="onMarkAllAsRead()"
      >
        <fdt-icon name="checkDoubleLine" size="20"></fdt-icon>Mark all as read
      </button>
    }
    <fdt-tab-group
      [selectedIndex]="tabSelectedIndex"
      (selectedTabChange)="onSelectedTabChange($event)"
      class="gray-bg inbox-list__tab-group"
    >
      <fdt-tab>
        <ng-template fdtTabLabel>New</ng-template>
        <div class="inbox-list__tab-content">
          @if (!unreadMessagesVMSignal().totalCount) {
            <div class="inbox-list__empty-view">
              <app-empty-view
                header="0 new Notifications"
                subtitle="There aren't any updates with the portal"
              ></app-empty-view>
            </div>
          }
          @if (unreadMessagesVMSignal().isBusy) {
            <div *fdtSkeleton="true; count: 3; height: '250px'; animation: 'pulse'"></div>
          } @else {
            @for (message of unreadMessagesVMSignal().data; track $index) {
              <app-inbox-message [message]="message"></app-inbox-message>
            }
          }
        </div>
      </fdt-tab>
      <fdt-tab>
        <ng-template fdtTabLabel>Read</ng-template>
        <div class="inbox-list__tab-content">
          @if (!readMessagesVMSignal().totalCount) {
            <div class="inbox-list__empty-view">
              <app-empty-view
                header="0 new Notifications"
                subtitle="There aren't any updates with the portal"
              ></app-empty-view>
            </div>
          }
          @if (readMessagesVMSignal().isBusy) {
            <div *fdtSkeleton="true; count: 3; height: '250px'; animation: 'pulse'"></div>
          } @else {
            @for (message of readMessagesVMSignal().data; track $index) {
              <app-inbox-message [message]="message"></app-inbox-message>
            }
          }
        </div>
      </fdt-tab>
    </fdt-tab-group>
  </div>
</div>
