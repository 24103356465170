import { Injectable } from '@angular/core';

import { Amount, BaseApiService, BaseGetRequestUrlParams } from '@1stdigital/ng-sdk/core';
import { GetUrlParams } from '@1stdigital/ng-sdk/core/src/services/base-api.models.internal';
import {
  AccountDetailsDto,
  AssetAccountSelectListItem,
  CryptoWalletAddressSelectListItem,
  CurrencyDropdownListItem,
  CurrencySelectListItemDto,
  EstimatedGasFeeDto,
  InstructionCreateResponseDto,
  LegalEntityBankAccountSelectListItemDto,
  PublicRsnAccountDto,
  PublicRsnAccountHistoryDto,
  RsnInstructionCreateDto,
  ThirdPartyCryptoDetailsDto,
  ThirdPartyTransferFiatInstructionCreateDto,
  TransferInCryptoInstructionCreateDto,
  TransferInFiatInstructionCreateDto,
  TransferOutCryptoDetailsDto,
  TransferOutFiatDetailsDto,
  WithdrawalCryptoWalletAddressDto,
} from '@app/asset-transfer/shared/models';
import { IntermediaryBankAccountDto } from '@app/core/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetTransferApiService extends BaseApiService {
  rsnSupportedCurrencies?: number[];

  createTransferInCryptoInstruction(
    model: TransferInCryptoInstructionCreateDto
  ): Observable<InstructionCreateResponseDto> {
    return this.post('asset-transfer/transfer-in-crypto', model);
  }

  createTransferInInstruction(model: TransferInFiatInstructionCreateDto): Observable<InstructionCreateResponseDto> {
    return this.post('asset-transfer/transfer-in-fiat', model);
  }

  createTransferOutInstruction(model: TransferOutFiatDetailsDto): Observable<InstructionCreateResponseDto> {
    return this.post('asset-transfer/transfer-out-fiat', model);
  }

  createTransferOutCryptoInstruction(model: TransferOutCryptoDetailsDto): Observable<InstructionCreateResponseDto> {
    return this.post('asset-transfer/transfer-out-crypto', model);
  }

  createThirdPartyFiatInstruction(
    model: ThirdPartyTransferFiatInstructionCreateDto
  ): Observable<InstructionCreateResponseDto> {
    return this.post(`asset-transfer/third-party-transfer-fiat`, model);
  }

  createThirdPartyTransferCryptoInstruction(
    model: ThirdPartyCryptoDetailsDto
  ): Observable<InstructionCreateResponseDto> {
    return this.post('asset-transfer/third-party-transfer-crypto', model);
  }

  createRsnInstruction(model: RsnInstructionCreateDto): Observable<InstructionCreateResponseDto> {
    return this.post(`asset-transfer/rsn-payment`, model);
  }

  getCryptoWalletsForWithdrawalSelectList(assetMasterId: number): Observable<WithdrawalCryptoWalletAddressDto[]> {
    return this.get(`asset-transfer/crypto-wallet-address/withdrawal/${assetMasterId}`);
  }

  getAccountsSelectList(assetMasterId: number): Observable<AssetAccountSelectListItem[]> {
    return this.get('asset-transfer/accounts-select-list', {
      assetMasterId,
    });
  }

  getIsFirstTimeTransferIn(bankAccountId: number): Observable<boolean> {
    return this.get(`asset-transfer/transfer-in-fiat/${bankAccountId}/is-first`);
  }

  getCryptoWalletSelectList(id: number): Observable<CryptoWalletAddressSelectListItem[]> {
    return this.get<CryptoWalletAddressSelectListItem[]>(`asset-transfer/crypto-wallets-select-list/${id}`);
  }

  getTransferInBankAccountsSelectList(): Observable<LegalEntityBankAccountSelectListItemDto[]> {
    return this.get('asset-transfer/transfer-in-fiat/bank-accounts-select-list');
  }

  getTransferOutBankAccountsSelectList(): Observable<LegalEntityBankAccountSelectListItemDto[]> {
    return this.get('asset-transfer/transfer-out-fiat/bank-accounts-select-list');
  }

  getThirdPartyBankAccountsSelectList(): Observable<LegalEntityBankAccountSelectListItemDto[]> {
    return this.get('asset-transfer/third-party-transfer-fiat/bank-accounts-select-list');
  }

  getCurrencyList(): Observable<CurrencyDropdownListItem[]> {
    return this.get<CurrencySelectListItemDto[]>('asset-transfer/currencies-select-list').pipe(
      map((currencies: CurrencySelectListItemDto[]) =>
        currencies.map((currency) => ({ ...currency, rsnSupported: this.isRsnCurrencySupported(currency.id) }))
      )
    );
  }

  getTransactionFee(params: GetUrlParams): Observable<Amount> {
    return this.get('asset-transfer/transaction-fee', params);
  }

  getEstimatedGasFee(assetAccountId: number, transactionAmount: number): Observable<EstimatedGasFeeDto> {
    return this.get(`asset-transfer/estimated-gas-fee/${assetAccountId}/${transactionAmount}`);
  }

  getIntermediaryBank(LegalEntityBankAccountId: number | string): Observable<IntermediaryBankAccountDto> {
    return this.get(`asset-transfer/bank-account/${LegalEntityBankAccountId}/intermediary-bank`);
  }

  getAccountDetails(accountNumber: string): Observable<AccountDetailsDto> {
    return this.get(`asset-transfer/account-details/${accountNumber}`);
  }

  getPublicRsnAccounts(): Observable<PublicRsnAccountDto[]> {
    return this.get(`asset-transfer/public-rsn-accounts`);
  }

  getPublicRsnAccountsHistory(params: BaseGetRequestUrlParams): Observable<PublicRsnAccountHistoryDto[]> {
    return this.get(`asset-transfer/account-rsn-history-list`, params);
  }

  getRsnSupportedCurrencies(): Observable<number[]> {
    return this.get<{ assetMasterIds: number[] }>(`asset-transfer/rsn-payment/supported-currencies`).pipe(
      map(({ assetMasterIds }) => assetMasterIds)
    );
  }

  setRsnSupportedCurrencies(currencies: number[]): void {
    this.rsnSupportedCurrencies = currencies;
  }

  isRsnCurrencySupported(currencyId: number): boolean {
    return this.rsnSupportedCurrencies ? this.rsnSupportedCurrencies.includes(currencyId) : false;
  }
}
