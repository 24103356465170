import { Injectable, signal } from '@angular/core';

import {
  InboxMarkAllReadResponse,
  InboxMessagesResponse,
  InboxMessageStore,
} from '@app/core/models/interfaces/inbox.interface';
import { CourierService } from '@app/core/services/courier.service';
import { Observable, tap } from 'rxjs';

const INITIAL_VALUE: InboxMessageStore = {
  pageInfo: {
    hasNextPage: false,
  },
  data: [],
  totalCount: 0,
  isBusy: false,
};

@Injectable({
  providedIn: 'root',
})
export class InboxStoreService {
  private _unreadMessagesVMSignal = signal<InboxMessageStore>(INITIAL_VALUE);
  private _readMessagesVMSignal = signal<InboxMessageStore>(INITIAL_VALUE);

  public readMessagesVMSignal = this._readMessagesVMSignal.asReadonly();
  public unreadMessagesVMSignal = this._unreadMessagesVMSignal.asReadonly();

  public ws$ = this.courierService.ws$;

  constructor(private courierService: CourierService) {}

  initWebSocket(clientId: string): void {
    this.courierService.initWebSocket(clientId);
  }

  getMessages(limit = 500): Observable<InboxMessagesResponse> {
    this._unreadMessagesVMSignal.update((state) => ({
      ...state,
      isBusy: true,
    }));

    this._readMessagesVMSignal.update((state) => ({
      ...state,
      isBusy: true,
    }));

    return this.courierService.getMessages(limit).pipe(
      tap(({ data }) => {
        this._unreadMessagesVMSignal.set({
          ...data.unread,
          isBusy: false,
        });

        this._readMessagesVMSignal.set({
          ...data.read,
          isBusy: false,
        });
      })
    );
  }

  markAllAsRead(): Observable<InboxMarkAllReadResponse> {
    return this.courierService.markAllAsRead().pipe(
      tap(() => {
        this._readMessagesVMSignal.update((state) => ({
          ...state,
          data: this._unreadMessagesVMSignal().data.concat(state.data),
          totalCount: this._unreadMessagesVMSignal().data.length + state.totalCount,
        }));

        this._unreadMessagesVMSignal.update(() => INITIAL_VALUE);
      })
    );
  }

  disconnectWs(): void {
    this.courierService.disconnectWs();
  }
}
