<div class="inbox-message" [class.inbox-message--unread]="!message.read">
  <div class="inbox-message__icon">
    <!-- TODO: We should define how the icons will be recieved, mpst probably it would be an img src -->
    <fdt-icon [name]="'questionLine'" size="20"></fdt-icon>
  </div>
  <div class="inbox-message__content">
    <span class="inbox-message__date">{{ message.created | fdtDate: 'medium' }}</span>
    <span class="inbox-message__title">{{ message.title }}</span>
    <span class="inbox-message__text"> {{ message.preview }} </span>
    @if (reviewAction) {
      <p>
        <button class="button-1" fdt-button color="primary">{{ reviewAction.content }}</button>
      </p>
    }
  </div>
</div>
