import { fdtProp } from '@1stdigital/ng-sdk/annotations';
import { BankAccountDto, IntermediaryBankAccountDto } from '@app/core/models/interfaces/bank-account.interface';

export class IntermediaryBankAccountModel implements IntermediaryBankAccountDto {
  @fdtProp('Bank Name', 'string')
  bankName: string = '';
  @fdtProp('SWIFT Code', 'string')
  swiftCode: string = '';
  @fdtProp('Country', 'string')
  country: string = '';
  @fdtProp('Bank Address', 'string')
  bankAddress: string = '';
}

export class BankAccountModel implements BankAccountDto {
  @fdtProp('Account Holder Name', 'string')
  bankAccountHolderName: string = '';
  @fdtProp('Bank Name', 'string')
  bankName: string = '';
  @fdtProp('Bank Account No.', 'string')
  bankAccountNumber: string = '';

  // @fdtProp('Intermediary Bank Info', IntermediaryBankAccountModel)
  intermediaryBankAccount?: IntermediaryBankAccountModel;
}
