import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CdkScrollable } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component, computed, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router, RouterModule, RouterOutlet } from '@angular/router';

import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { ButtonComponent } from '@1stdigital/ng-sdk/button';
import { AppUserService, IconName } from '@1stdigital/ng-sdk/core';
import { DialogService } from '@1stdigital/ng-sdk/dialog';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { AnalyticsEvents } from '@app/core/models/analytics-events.type';
import { ApplicationSettingsService, InboxStoreService } from '@app/core/services';
import { ClientService } from '@app/core/services/client.service';
import { ClientsAccessorApiService } from '@app/core/services/clients-accessor-api.service';
import { EnvironmentLoaderService } from '@app/core/services/environment-loader.service';
import { LogoutService } from '@app/core/services/logout.service';
import { UserInfoApiService } from '@app/core/services/user-info-api.service';
import { HelpWidgetService } from '@app/help-center/help-widget.service';
import { HelpWidgetComponent } from '@app/help-center/shared/components/help-widget/help-widget.component';
import { LayoutModuleSettings } from '@app/layout/layout-module.settings';
import { NpsSurveyPopupComponent } from '@app/layout/nps-survey-popup/nps-survey-popup.component';
import { TrackEventDirective } from '@app/shared/directives/track-event.directive';
import { TourHighlightConfig } from '@app/tour/models/tour.interface';
import { TourService } from '@app/tour/tour.service';
import { TourHighlightDirective } from '@app/tour/tour-highlight.directive';
import { WelcomeModalComponent } from '@app/tour/welcome-modal/welcome-modal.component';
import { MsalService } from '@azure/msal-angular';
import { TokenClaims } from '@azure/msal-common';
import { filter, forkJoin, skip, switchMap } from 'rxjs';
import { InboxWidgetComponent } from 'src/app/inbox/inbox-widget/inbox-widget.component';

import { AnnouncementComponent } from '../announcement/announcement.component';

interface NavItem {
  label: string;
  icon: IconName;
  link?: string;
  hidden?: boolean;
  analyticEvent?: AnalyticsEvents;
  tourConfig?: TourHighlightConfig;
  children?: NavItem[];
}

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatMenuModule,
    RouterOutlet,
    RouterModule,
    FdtIconModule,
    AnnouncementComponent,
    HelpWidgetComponent,
    CdkScrollable,
    ButtonComponent,
    InboxWidgetComponent,
    TrackEventDirective,
    TourHighlightDirective,
    NpsSurveyPopupComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss',
})
export class LayoutComponent implements OnInit, OnDestroy {
  client$ = this.clientService.activeClient$;
  serviceEntity$ = this.clientService.activeServiceEntity$;
  showHelpWidgetSignal = this.helpWidgetService.isVisibleSignal;
  hasNewMessageSignal = computed(() => this.inboxStore.unreadMessagesVMSignal().totalCount > 0);
  name?: string;
  isMobileView = false;
  isNavOpened = true;
  isInboxEnabled = !!this.envService.environment['enableInbox'];

  readonly navItems: NavItem[] = [
    {
      label: 'Overview',
      icon: 'home3Line',
      link: '/dashboard',
      analyticEvent: 'Overview clicked',
    },
    {
      label: 'Asset Holdings',
      icon: 'pieChartLine',
      link: '/asset-holdings',
      analyticEvent: 'Asset holdings clicked',
      tourConfig: { step: 2, name: 'onboarding' },
    },
    {
      label: 'Activity',
      icon: 'historyLine',
      link: '/activity',
      analyticEvent: 'Activity clicked',
      tourConfig: { step: 3, name: 'onboarding' },
    },
    {
      label: 'Cards',
      icon: 'bankCard2Line',
      link: '/card',
      hidden: !this.canShowCardMenuItem(),
      analyticEvent: 'FDT Card clicked',
    },
    {
      label: 'Instructions',
      icon: 'home3Fill',
      tourConfig: { step: 4, name: 'onboarding' },
      children: [
        {
          label: 'Asset Transfer',
          icon: 'arrowUpDownLine',
          link: '/asset-transfer',
          analyticEvent: 'Asset transfer clicked',
        },
        {
          label: 'OTC',
          icon: 'loopLeftLine',
          link: '/otc',
          analyticEvent: 'Digital asset exchange clicked',
        },
      ],
    },
    // {
    //   label: 'Treasury Bills',
    //   icon: 'redPacketLine',
    //   link: '/treasury-bills',
    // },
  ];

  constructor(
    private envService: EnvironmentLoaderService,
    private router: Router,
    private authService: MsalService,
    private helpWidgetService: HelpWidgetService,
    private logoutService: LogoutService,
    private clientService: ClientService,
    private clientsAccessorApiService: ClientsAccessorApiService,
    private userInfoApiService: UserInfoApiService,
    private breakpointObserver: BreakpointObserver,
    private appUserService: AppUserService,
    private dialogService: DialogService,
    private tourService: TourService,
    private appSettingsService: ApplicationSettingsService,
    private destroyRef: DestroyRef,
    private inboxStore: InboxStoreService
  ) {
    this.handleWelcomeModalOpening();
    this.handleTourListeners();
  }

  ngOnInit(): void {
    const idTokenClaims: TokenClaims | undefined = this.authService.instance.getActiveAccount()?.idTokenClaims;
    this.name = idTokenClaims?.name;

    this.clientService.activeServiceEntity$
      .pipe(
        skip(1), // initially resolved in resolver
        switchMap(() => {
          return forkJoin([
            this.clientsAccessorApiService.getAssetMasterIds(),
            this.userInfoApiService.getUserInfoResponse(),
          ]);
        })
      )
      .subscribe(([assetMasterIds, userInfo]) => {
        this.appUserService.setAppAvailableAssets(assetMasterIds);
        this.userInfoApiService.setUserInfo(userInfo);
        // dynamically show card
        this.navItems.find((item) => item.link === '/card')!.hidden = !this.canShowCardMenuItem();
      });

    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result: BreakpointState) => {
        this.isMobileView = result.matches;
        this.isNavOpened = !result.matches;
      });

    if (this.isInboxEnabled) {
      const clientId = this.clientService.getClientSelection()!.clientId + '';

      this.inboxStore.initWebSocket(clientId);
    }
  }

  private canShowCardMenuItem(): boolean {
    if (this.envService.environment['enableCard']) {
      const cardApplication = !!this.envService.environment['cardApplication'];

      if (!cardApplication) {
        return !!(
          this.userInfoApiService.getUserInfo()?.isCardHolder || this.userInfoApiService.getUserInfo()?.isCardAdmin
        );
      }

      // If cardApplication is set, show the card menu item
      return true;
    }

    // If enableCard is not set, hide the card menu item
    return false;
  }

  onOpenedChange(opened: boolean): void {
    this.isNavOpened = opened;
  }

  onToggleSidebar(): void {
    this.isNavOpened = !this.isNavOpened;
  }

  onCloseNav(): void {
    if (this.isMobileView) {
      this.isNavOpened = false;
    }
  }

  onOpenEntitySwitcherClick(): void {
    if (this.isMobileView) {
      this.isNavOpened = false;
    }

    this.router.navigate(['change-client-service']);
  }

  onLogout(): void {
    this.logoutService.logout();
  }

  onOnboardingStart(): void {
    this.tourService.startTour('onboarding');
  }

  private handleWelcomeModalOpening(): void {
    const layoutSettings = this.appSettingsService.getSettings<LayoutModuleSettings>('layout');
    const onboardingTourState = layoutSettings?.tourGuide?.onboarding;

    if (
      !onboardingTourState ||
      (!onboardingTourState.finish && onboardingTourState.lastSessionId !== this.logoutService.getAuthTime())
    ) {
      this.dialogService.open(WelcomeModalComponent, { disableClose: true, closeOnNavigation: false, size: 'md' });
    }
  }

  private handleTourListeners(): void {
    this.tourService.stateChanges$
      .pipe(
        filter(({ name }) => name === 'onboarding' || name === 'relaunch-onboarding'),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(({ state, name }) => this.tourService.updateTourSettings(state, name));
  }

  ngOnDestroy(): void {
    this.inboxStore.disconnectWs();
  }
}
