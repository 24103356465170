import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';
import { FdtDatePipe } from '@1stdigital/ng-sdk/shared';
import { InboxAction, InboxMessage } from '@app/core/models/interfaces/inbox.interface';

import { ImageDirective } from '../../shared/directives/image.directive';

@Component({
  selector: 'app-inbox-message',
  standalone: true,
  imports: [FdtIconModule, FdtDatePipe, FdtButtonModule, ImageDirective],
  templateUrl: './inbox-message.component.html',
  styleUrl: './inbox-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessageComponent {
  @Input() message!: InboxMessage;

  get reviewAction(): InboxAction | null {
    const hasReview = this.message.tags?.includes('instruction-review');

    if (!hasReview) {
      return null;
    }

    if (this.message.actions) {
      return this.message.actions[0];
    }

    return null;
  }
}
