import { Component, Input, ViewEncapsulation } from '@angular/core';

import { IconComponent } from '@1stdigital/ng-sdk/icon';

@Component({
  selector: 'app-info-block',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './info-block.component.html',
  styleUrl: './info-block.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class InfoBlockComponent {
  @Input() showIcon = true;
}
