import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtFormFieldModule, FdtHintDirective } from '@1stdigital/ng-sdk/form-field';
import { FdtSkeletonDirective } from '@1stdigital/ng-sdk/loading-indicators';
import { FdtTabModule } from '@1stdigital/ng-sdk/tab';
import { InboxStoreService } from '@app/core/services';
import { EmptyViewComponent } from '@app/shared/empty-view/empty-view.component';
import { AppFormModule } from '@app/shared/form/form.module';
import { InfoBlockComponent } from '@app/shared/info-block/info-block.component';
import { EMPTY, mergeMap, startWith } from 'rxjs';

import { InboxMessageComponent } from '../inbox-message/inbox-message.component';

@Component({
  selector: 'app-inbox-message-list',
  standalone: true,
  imports: [
    EmptyViewComponent,
    InfoBlockComponent,
    FdtButtonModule,
    FdtFormFieldModule,
    AppFormModule,
    FdtHintDirective,
    FdtTabModule,
    InboxMessageComponent,
    FdtSkeletonDirective,
  ],
  templateUrl: './inbox-message-list.component.html',
  styleUrl: './inbox-message-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessageModalComponent implements OnInit {
  @Output() closeWidget = new EventEmitter();

  tabSelectedIndex = 0;
  isBusy = false;
  unreadMessagesVMSignal = this.inboxStore.unreadMessagesVMSignal;
  readMessagesVMSignal = this.inboxStore.readMessagesVMSignal;

  constructor(private inboxStore: InboxStoreService) {}

  ngOnInit(): void {
    this.inboxStore.ws$
      .pipe(
        startWith(EMPTY),
        mergeMap(() => this.inboxStore.getMessages())
      )
      .subscribe();
  }

  onSelectedTabChange(event: MatTabChangeEvent): void {
    this.tabSelectedIndex = event.index;
  }

  onModalClose(): void {
    this.closeWidget.emit();
  }

  onMarkAllAsRead(): void {
    this.inboxStore.markAllAsRead().subscribe();
  }
}
