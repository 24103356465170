import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';

import { MatDialogRef } from '@angular/material/dialog';

import { FdtButtonModule } from '@1stdigital/ng-sdk/button';
import { FdtIconModule } from '@1stdigital/ng-sdk/icon';

import { ModalEntityCaptionComponent } from './modal-entity-caption/modal-entity-caption.component';

@Component({
  selector: 'app-full-size-modal-wrapper',
  standalone: true,
  imports: [FdtIconModule, ModalEntityCaptionComponent, RouterLink, FdtButtonModule],
  templateUrl: './full-size-modal-wrapper.component.html',
  styleUrl: './full-size-modal-wrapper.component.scss',
})
export class FullSizeModalWrapperComponent {
  @Output() closeModal = new EventEmitter<boolean>();
  @Input() header!: string;
  @Input() showEntity: boolean = false;
  @Input() closeDialogOnCloseClick = true;

  constructor(public dialogRef: MatDialogRef<FullSizeModalWrapperComponent>) {}

  /**
   * Close the dialog, considering whether redirection to the home screen is done (for logo).
   *
   * @param homeRedirect - A boolean flag indicating whether the close event also navigate to the home screen.
   *                         If true, indicates a close event with redirection to the home screen; if false or undefined,
   *                         indicates a simple close event without redirection.
   */
  onCloseClick(homeRedirect?: boolean): void {
    // if 'homeRedirect' is true, indicating a close event with redirection to the home screen,
    // set the close response to false, indicating that the dialog is closed and no further action is needed (f.e redirect).
    // if 'homeRedirect' is false or undefined, indicating a simple close event,  leave the close response undefined;
    // Both values are negative because positive is considered the data passed after successful user action in the modal, like submit etc.

    if (this.closeDialogOnCloseClick) {
      this.dialogRef.close(homeRedirect ? false : undefined);
    }

    this.closeModal.emit(homeRedirect);
  }
}
